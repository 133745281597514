(function () {

  'use strict';

  const el = document.querySelectorAll('.tabs');
  const tabNavigationLinks = document.querySelectorAll('.tabs__link');
  const tabContentContainers = document.querySelectorAll('.tabs__tab');
  const secondaryContentContainers = document.querySelectorAll('.tabs__secondary-content > .tabs__tab');
  let activeIndex = 0;

  /**
   * handleClick
   * @description Handles click event listeners on each of the links in the
   *   tab navigation. Returns nothing.
   * @param {HTMLElement} link The link to listen for events on
   * @param {Number} index The index of that link
   */
  function handleClick(link, index) {
    link.addEventListener('click', function (e) {
      e.preventDefault();
      goToTab(index);
    });
  }

  /**
   * goToTab
   * @description Goes to a specific tab based on index. Returns nothing.
   * @param {Number} index The index of the tab to go to
   */
  function goToTab(index) {
    if (index !== activeIndex && index >= 0 && index <= tabNavigationLinks.length) {
      tabNavigationLinks[activeIndex].classList.remove('is-active');
      tabNavigationLinks[index].classList.add('is-active');
      tabContentContainers[activeIndex].classList.remove('is-active');
      tabContentContainers[index].classList.add('is-active');
      if (secondaryContentContainers && secondaryContentContainers.length > 0) {
        secondaryContentContainers[activeIndex] && secondaryContentContainers[activeIndex].classList.remove('is-active');
        secondaryContentContainers[index].classList.add('is-active');
      }
      activeIndex = index;
    }
  }

  /**
   * init
   * @description Initializes the component by removing the no-js class from
   *   the component, and attaching event listeners to each of the nav items.
   *   Returns nothing.
   */
  for (let e = 0; e < el.length; e++) {
    el[e].classList.remove('no-js');
  }

  for (let i = 0; i < tabNavigationLinks.length; i++) {
    const link = tabNavigationLinks[i];
    handleClick(link, i);
  }

  jQuery(".search-container #all_search").removeClass("active-tab-title");
  jQuery(".search-container #product-mbl-tab").addClass("active-tab-title");
  jQuery(".tab-accordion-title").click(function(){
    //jQuery(".tab-accordion-title").removeClass("active-tab-title");
    //jQuery(".tabs__tab").removeClass("is-active");
    jQuery(this).next(".tabs__tab").toggleClass("is-active");
    jQuery(this).toggleClass("active-tab-title");
  });

  var active_tab = window.location.hash.substring(1);
  if(active_tab != ""){
    jQuery(".tab-accordion-title[name='"+ active_tab +"']").addClass("active-tab-title");
    jQuery(".tab-accordion-title:first").removeClass("active-tab-title");
  }else {
    jQuery(".tab-accordion-title:first").addClass("active-tab-title");
  }



  jQuery(".no-redirection-tab .tabs__nav li a").click(function() {
    if(jQuery(".no-redirection-tab").length) {
      if (jQuery(this).hasClass("is-active")) {
        var tabName = jQuery(this).attr("name");
        console.log(tabName);
         window.location.hash = tabName;
      }
    }
  });

  if (jQuery('.img-tabs-container').length > 0) {
    jQuery('.tabs__content').each(function() {
      if (jQuery(this).find('.image-tile').length > 5) {
        jQuery(this).removeClass('no-slider');
        jQuery(this).slick({
          arrows: true,
          dots: false,
          autoplay: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 787,
              settings: "unslick"
            },
          ],
        });
      }
    })
  }

})();

jQuery(document).ready(function(){ 
  jQuery('.tab-link').click(function(e){  
    e.preventDefault();
    jQuery(".tabs__tabs").removeClass('is-active');
    jQuery(".tabs__content_outlet[data-id='"+jQuery(this).attr('data-id')+"']").parent().addClass("is-active");
    jQuery(".tab-link").removeClass('is-active');
    jQuery(this).parent().find(".tab-link").addClass('is-active');
   });
});
